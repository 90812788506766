/**
 * This component displays the record the user is currently working on and allows
 * the user to change records. See Records view.
 */
import { useMemo, useRef } from 'react';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import { SelectItemOptionsType } from 'primereact/selectitem';
import { mapRecordToRecordListItem } from '../../utils';
import { useRecordsUI, useRecords } from '../../hooks';
import { useSelectedObjectType } from '../../../objectTypes';

export function RecordDropdown() {
  const [objectType] = useSelectedObjectType();
  const { singularLabel = 'record', pluralLabel = 'records' } = objectType || {};
  const { records, selectedRecord, setSelectedRecord } = useRecords();
  const dropdownRef = useRef<Dropdown>(null);

  const options: SelectItemOptionsType | [] = useMemo(() => {
    return records?.data?.data?.map?.(mapRecordToRecordListItem) || [];
  }, [records?.data]);

  const value: string | undefined = useMemo(() => {
    return selectedRecord ? mapRecordToRecordListItem(selectedRecord).value : undefined;
  }, [selectedRecord]);

  const { openModal } = useRecordsUI();

  // console.log('RecordDropdown', { objectType, singularLabel, pluralLabel, records, selectedRecord, options, value });

  /* Dropdown for the record controls */
  return (
    <Dropdown
      ref={dropdownRef}
      value={value}
      options={options}
      loading={records?.isFetching}
      placeholder={records?.isFetching ? `Loading ${pluralLabel}` : `Select a ${singularLabel}`}
      onChange={(e) => {
        setSelectedRecord(e.target.value);
      }}
      panelFooterTemplate={() => (
        <Button
          text
          iconPos='right'
          onClick={() => {
            dropdownRef.current?.hide();
            openModal();
          }}
          icon='pi pi-search'
          label='Advanced Search'
          size='small'
        />
      )}
      pt={{
        footer: { className: 'flex justify-center px-3 py-3 border-t border-gray-200' },
        panel: { style: { maxWidth: '10rem' } },
      }}
    />
  );
}

export default RecordDropdown;
