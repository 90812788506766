import { useEffect, useMemo, useState } from 'react';
import { Template, useListTemplatesQuery } from '../../../../app/api/templates';
import { useTemplates } from '../../hooks';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import TemplateSettingsModal from '../settings/TemplateSettingsModal';
import useTemplateUI from '../../hooks/useTemplateUI';
import TemplateModal from './TemplateModal';

interface TemplateOption {
  label: string;
  value: Template;
}

export default function TemplateDropdown() {
  const { openModal } = useTemplateUI();
  const { selectedTemplate, selectTemplate } = useTemplates();
  const [templateOptions, setTemplateOptions] = useState<TemplateOption[]>([]);
  const { data, isFetching } = useListTemplatesQuery({ page: 1, limit: 10, sort: '-updatedAt' });
  const [showTemplateSettings, setShowTemplateSettings] = useState(false);

  const templates = useMemo(() => (data ? data.data : []), [data]);

  // Use effect assign template options and ensure selected template is always in the list
  useEffect(() => {
    if (!selectedTemplate) return;

    // Removes the selected template from the list of templates, so we do not have duplicates
    const filteredTemplates = templates.filter((template) => template.id !== selectedTemplate?.id);
    setTemplateOptions([
      { label: selectedTemplate.name || '', value: selectedTemplate },
      ...filteredTemplates.map((template) => ({ label: template.name || '', value: template })),
    ]);
  }, [selectedTemplate, templates]);

  // See dropdown to see where these templates are used.
  const valueTemplate = (option: TemplateOption) => {
    const { value: template } = option || {};
    return (
      <p
        style={{ fontSize: '1rem' }}
        className={`before:content-['●'] before:mr-2 before:text-xl ${
          template?.isActive ? ' before:text-blue-500 ' : 'before:text-slate-200'
        }`}
      >
        {template?.name || 'Select a Template'}
      </p>
    );
  };

  const itemTemplate = (option: TemplateOption) => {
    const { value: template } = option || {};
    return (
      <p
        className={`before:content-['●'] before:mr-2 before:text-xl ${
          template.isActive ? ' before:text-blue-500' : 'before:text-slate-200'
        }`}
      >
        {template?.name}
      </p>
    );
  };

  const dropdownFooterTemplate = () => {
    return <Button text iconPos='right' onClick={openModal} icon='pi pi-search' label='Advanced Search' size='small' />;
  };

  return (
    <>
      {/* dropdown label and settings button */}
      <label htmlFor='template-dropdown' className='flex items-center justify-between'>
        <p>Templates</p>
        <span className='pi pi-cog mr-2' onClick={() => setShowTemplateSettings(true)} />
      </label>

      {/* dropdown menu */}
      <Dropdown
        id='template-dropmdown'
        options={templateOptions}
        value={selectedTemplate}
        onChange={(e) => selectTemplate(e.value)}
        placeholder='Select a Template'
        itemTemplate={itemTemplate}
        loading={isFetching}
        panelFooterTemplate={dropdownFooterTemplate}
        valueTemplate={valueTemplate}
        pt={{
          panel: { style: { maxWidth: '10rem' } },
          footer: { style: { padding: 5, paddingBottom: 10, display: 'flex', justifyContent: 'center' } },
        }}
      />

      <TemplateModal />
      {/* modal for template settings */}
      <TemplateSettingsModal visible={showTemplateSettings} onHide={() => setShowTemplateSettings(false)} />
    </>
  );
}
