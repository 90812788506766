import { useCallback, useEffect, useMemo, useState } from 'react';
import { DataView, DataViewPassThroughOptions } from 'primereact/dataview';
import SearchButtonInput from '../../../../components/SearchButtonInput';
import GridItem from './grid/TemplateListGridItem';
import TemplateListItem from './table/TemplateListItem';
import { Tooltip } from 'primereact/tooltip';
import { useDebounce } from 'primereact/hooks';
import { useTemplates } from '../../hooks';
import { Dropdown } from 'primereact/dropdown';
import { Button } from 'primereact/button';
import TemplateListEmptyState from './TemplateListEmptyState';
import TemplateListGridItemSkeleton from './grid/TemplateListGridItemSkeleton';
import { SelectButton } from 'primereact/selectbutton';

import type { Template } from '../../../../app/api/templates';
import { useNavigate } from 'react-router-dom';
import useTemplateUI from '../../hooks/useTemplateUI';
import { Paginator } from 'primereact/paginator';
import useTemplateList from '../../hooks/useTemplateList';
import { getSearchRegex } from '../../utils';

const DEFAULT_TEMPLATE_LIMIT = 20;

type ActiveOption = 'all' | 'active' | 'inactive';
const activeOptions: { label: string; value: ActiveOption }[] = [
  { label: 'All', value: 'all' },
  { label: 'Active', value: 'active' },
  { label: 'Inactive', value: 'inactive' },
];

// See Paginator component for the shape of the page data.
type PageDataType = { first: number; rows: number; page: number; pageCount: number };

const Templates = () => {
  const [activeFilter, setActiveFilter] = useState<ActiveOption>('all');
  const [pageData, setPageData] = useState<PageDataType>({
    first: 0,
    rows: DEFAULT_TEMPLATE_LIMIT,
    page: 0,
    pageCount: 0,
  });
  const [layout, setLayout] = useState<any>('grid');

  const { selectTemplate } = useTemplates();
  const { fetchTemplates, templateData, meta } = useTemplateList();
  const { closeModal } = useTemplateUI();

  const searchString = useDebounce('', 400);
  const searchValue = searchString[1];
  const setSearchValue = searchString[2];

  const navigate = useNavigate();

  const templates = useMemo(() => templateData?.data, [templateData?.data]);
  const isFetching = useMemo(() => meta.isFetching, [meta.isFetching]);

  const ptOptions: DataViewPassThroughOptions = {
    grid: {},
    header: { style: { padding: '.5em 1em' } },
    emptyMessage: {
      className: 'p-10 text-center w-full items-center flex justify-center',
      style: { minHeight: 'calc(100vh - 60px)' },
    },
    content: { style: { background: 'transparent' } },
    loadingOverlay: { style: { backgroundColor: 'rgba(255,255,255,.7)', zIndex: 100 } },
  };

  if (layout === 'grid')
    ptOptions.grid = {
      className: templates?.length ? 'grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-3 p-5' : '',
    };

  useEffect(() => {
    const filters: { [key: string]: string } = {};
    if (activeFilter !== 'all') filters.isActive = activeFilter === 'active' ? 'true' : 'false';
    if (searchValue?.length) filters.name = getSearchRegex(searchValue);

    fetchTemplates(pageData.page, pageData.rows, filters);
  }, [fetchTemplates, pageData, activeFilter, searchValue]);

  const handleSelectTemplate = useCallback(
    (template: Template) => {
      selectTemplate(template.id);
      navigate('/dashboard');
      closeModal();
    },
    [selectTemplate, navigate, closeModal],
  );

  const handleReload = useCallback(() => {
    // Set page data should always trigger a rerender as it is assigning a new reference object.
    setPageData({ first: 0, rows: DEFAULT_TEMPLATE_LIMIT, page: 1, pageCount: 0 });
    setActiveFilter('all');
  }, [setPageData, setActiveFilter]);

  const listItem = (template: Template) => <TemplateListItem template={template} onSelect={handleSelectTemplate} />;
  const gridItem = (template: Template) => <GridItem template={template} onSelect={handleSelectTemplate} />;
  const itemTemplate = layout === 'grid' ? gridItem : listItem;

  const handlePageChange = useCallback((pageData: PageDataType) => {
    // Need to use page + 1 as the pagedata starts from 0
    setPageData((prev) => ({ ...prev, ...pageData, page: pageData.page + 1 }));
  }, []);

  return (
    <div className='flex flex-col gap-4 h-full w-full overflow-hidden'>
      {/* HEADER */}
      <div className='flex-initial grid grid-cols-3 py-2 px-1'>
        {/* LEFT */}
        <div className='flex justify-start items-center gap-3 '>
          <Dropdown
            options={activeOptions}
            value={activeFilter}
            onChange={(e) => setActiveFilter(e.target.value)}
            className='p-inputtext-sm'
          />
          <SearchButtonInput onChange={(v) => setSearchValue(v)} />
        </div>

        {/* CENTER */}
        <div className='flex justify-center items-center'>
          <h4 className='text-2xl font-medium text-slate-600'>Select a Template</h4>
        </div>

        {/* RIGHT */}
        <div className='flex items-center justify-end gap-3'>
          <Button
            size='small'
            onClick={handleReload}
            disabled={isFetching}
            icon='pi pi-refresh'
            outlined
            severity='secondary'
            loading={isFetching}
          />
          
          <SelectButton
            value={layout}
            optionLabel='icon'
            pt={{ button: { style: { padding: '0.3rem 1rem 0.3rem' } } }}
            onChange={(e) => setLayout(e.value)}
            options={[
              { icon: <i className='pi pi-bars'></i>, value: 'list' },
              { icon: <i className='pi pi-th-large'></i>, value: 'grid' },
            ]}
          />
        </div>
      </div>

      {/* CONTENT */}
      <div className='flex-auto overflow-y-auto border bg-slate-50'>
        {isFetching ? (
          <div
            className='p-grid grid p-nogutter grid-nogutter grid-cols-3 md:grid-cols-4 xl:grid-cols-5 gap-3 p-5'
            data-pc-section='grid'
          >
            {Array.apply(null, Array(20)).map((_, index) => (
              <div key={index}>
                <TemplateListGridItemSkeleton />
              </div>
            ))}
          </div>
        ) : templates?.length ? (
          <div className='flex flex-col items-center min-h-[100%] justify-stretch'>
            <DataView
              value={isFetching ? [] : templates}
              itemTemplate={itemTemplate}
              header={false}
              layout={layout}
              pt={ptOptions}
              loading={meta.isFetching}
              className='h-full'
              emptyMessage="Couldn't find any templates"
              style={{ minHeight: '100%', width: '100%', flexGrow: '1' }}
            />
            <Paginator
              onPageChange={handlePageChange}
              rows={pageData.rows}
              style={{ marginTop: '1rem' }}
              first={pageData.first}
              totalRecords={templateData?.total}
              rowsPerPageOptions={[DEFAULT_TEMPLATE_LIMIT, 30, 40]}
            />
          </div>
        ) : (
          <div className='h-full flex justify-center items-center'>
            <TemplateListEmptyState onReload={handleReload} searching={Boolean(searchValue.length)} />
          </div>
        )}
        <Tooltip target='.active-badge' />
      </div>
    </div>
  );
};

export default Templates;
