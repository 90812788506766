import { useCallback, useEffect, useRef, useState } from 'react';
import { InputText } from 'primereact/inputtext';
import { useRecordsList } from '../../../hooks';

export default function RecordSearchButtonInput() {
  const [showInput, setShowInput] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  // Used to show the value in the input field instantly
  const [instantValue, setInstantValue] = useState<string>('');
  const { setParams } = useRecordsList();

  const handleButtonClick = () => {
    setShowInput((cur) => !cur);
  };

  // Used as a work-around because focusing from event handler doesn't work
  useEffect(() => {
    if (showInput) inputRef.current?.focus();
  }, [showInput]);

  const inputStyle = { padding: '8px 0px 8px 40px', minWidth: '0px', width: '30px' };
  if (showInput) inputStyle.width = '200px';

  const handleSearchValueChange = useCallback(
    (searchString: string) => {
      setInstantValue(searchString);
      setParams({ searchProperty: undefined, searchValue: searchString });
    },
    [setInstantValue, setParams],
  );

  return (
    <div>
      <span className='p-input-icon-left p-icon-'>
        <i className='pi pi-search cursor-pointer ml-3' onClick={handleButtonClick} />
        <InputText
          placeholder='Search'
          value={instantValue}
          style={inputStyle}
          ref={inputRef}
          onChange={(e) => handleSearchValueChange(e.target.value)}
          className={showInput ? '' : 'cursor-pointer'}
          onFocus={() => setShowInput(true)}
        />
        {/* {showInput && recordsSearch?.searchValue !== '' && (
          <i className='pi pi-times cursor-pointer -ml-8 pi-small' onClick={dispatchClearSearch} />
        )} */}
      </span>
    </div>
  );
}
