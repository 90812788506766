// import { useCallback, useEffect } from 'react';
import SideBar from './components/Sidebar';
import { DocumentViewer, DocumentProvider } from '../../features/documents';
import { RecordListModal } from '../../features/records';
import { RecordsProvider } from '../../features/records/contexts/RecordsContext';
import { ObjectTypesProvider } from '../../features/objectTypes';
import { TokensProvider } from '../../features/tokens';

const DashboardView = () => {
  return (
    <ObjectTypesProvider>
      <RecordsProvider>
        <TokensProvider>
          <DocumentProvider>
            <div style={{ height: '100vh' }} className='flex overflow-hidden no-wrap'>
              <div style={{ flex: '0 0 300px' }} className='h-screen overflow-y-auto'>
                <SideBar />
                <RecordListModal />
              </div>
              <div style={{ flex: '1 1 auto', background: '#F2F4FA' }}>
                <DocumentViewer />
              </div>
            </div>
          </DocumentProvider>
        </TokensProvider>
      </RecordsProvider>
    </ObjectTypesProvider>
  );
};

export default DashboardView;
