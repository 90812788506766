import { useMemo, useState } from 'react';
import { Worker, Viewer, SpecialZoomLevel, LoadError } from '@react-pdf-viewer/core';
import '@react-pdf-viewer/core/lib/styles/index.css';
import '@react-pdf-viewer/toolbar/lib/styles/index.css';
import { Panel } from 'primereact/panel';
import { useTemplates } from '../../templates';
import { useSelectedRecord } from '../../records';
import { useDocumentViewer } from '../hooks/useDocumentViewer';
import DocumentViewerControls from './DocumentViewerControls';
import EmptyState from './EmptyState';
import DocumentViewerSkeleton from './DocumentViewerSkeleton';
import { useDocument } from '../hooks';
import { DocumentViewerProvider } from '../contexts';

const DocumentViewer = () => {
  const { toolbarInstance } = useDocumentViewer();
  const { document } = useDocument();
  const { selectedTemplate } = useTemplates();
  const [selectedRecord] = useSelectedRecord();

  const [loadError, setLoadError] = useState<LoadError | null>(null);

  const missingData = useMemo(() => !selectedRecord || !selectedTemplate, [selectedRecord, selectedTemplate]);

  function renderError(error: LoadError) {
    setLoadError(error);

    return (
      <div className='w-full max-h-full overflow-y-auto'>
        <div className='flex justify-center '>
          <div className='w-full max-w-xl bg-white rounded-xl p-6 m-10'>
            <h3 className='text-center text-2xl mb-4'>Unable to load document</h3>
            <div className='flex flex-col gap-2 mb-5 text-md text-neutral-500'>
              <p>An error was encountered while loading the document.</p>
              <p>
                Try creating a new preview. If the error persists please contact{' '}
                <a href='mailto:support@documint.me'>Documint Support</a>
              </p>
            </div>
            <Panel header='Error details' toggleable className='w-full' collapsed={true}>
              {error.message}
            </Panel>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div id='document-viewer' className='relative' style={{ height: '100vh', backgroundColor: '#f4f7ff' }}>
      <div style={{ wordBreak: 'break-word' }} className='h-screen'>
        {document.isLoading ? (
          <DocumentViewerSkeleton />
        ) : document.isSuccess && document.data?.url && !missingData ? (
          <Worker workerUrl='https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js'>
            <Viewer
              fileUrl={document.data.url}
              defaultScale={SpecialZoomLevel.PageFit}
              plugins={[toolbarInstance]}
              renderError={renderError}
              renderLoader={() => <DocumentViewerSkeleton />}
            />
            {!loadError && !missingData && <DocumentViewerControls />}
          </Worker>
        ) : (
          <EmptyState />
        )}
      </div>
    </div>
  );
};

export default function DocumentViewerWrapper() {
  return (
    <DocumentViewerProvider>
      <DocumentViewer />
    </DocumentViewerProvider>
  );
}
