import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useApiKey, useAuth } from '../../features/auth';
import { ProgressBar } from 'primereact/progressbar';
import { Image } from 'primereact/image';
import { useLazyCheckTokenValidityQuery } from '../../app/api/hubspot';

const UNAUTHORIZED_REDIRECT_PATH = '/unauthorized';
type LoginStatus = {
  label: string;
  value: number;
};

const Login = () => {
  const { login } = useAuth();
  const apiKey = useApiKey();
  const [checkTokenValidity] = useLazyCheckTokenValidityQuery();
  const { state, search } = useLocation();
  const [loginStatus, setLoginStatus] = useState<LoginStatus>({ label: 'Loading...', value: 0 });
  const { redirectTo } = state || {};
  const navigate = useNavigate();
  const isLoggingInRef = useRef<boolean>(false);

  const redirectLogin = useCallback(() => {
    navigate(redirectTo || '/templates');
  }, [navigate, redirectTo]);

  const redirectApiKey = useCallback(() => {
    navigate('/api-key', { state: { redirectTo: redirectTo || '/templates' } });
  }, [navigate, redirectTo]);

  const redirectAuthorize = useCallback(() => {
    return navigate('/authorize');
  }, [navigate]);

  const redirectNoAuth = useCallback(() => {
    navigate(UNAUTHORIZED_REDIRECT_PATH);
  }, [navigate]);

  const doLogin = useCallback(async () => {
    try {
      isLoggingInRef.current = true;

      // Login
      setLoginStatus({ label: 'Logging in', value: 68 });
      const params = new URLSearchParams(search);
      const queryToken = params.get('session_token');
      const user = await login(queryToken);
      if (!user) redirectNoAuth();

      // Validate Scopes
      setLoginStatus({ label: 'Checking scopes', value: 84 });
      const tokenValidity = await checkTokenValidity();
      console.log('Token Validity:', tokenValidity);
      if (tokenValidity?.data?.error) {
        return redirectAuthorize();
      }

      // Validate the API Key
      setLoginStatus({ label: 'Validating API Key', value: 98 });
      const { error: apiKeyError, data: apiKeyData } = await apiKey.checkValidity({});
      if (apiKeyError || apiKeyData.isValid === false) return redirectApiKey();

      setLoginStatus({ label: 'Login successful', value: 100 });
      redirectLogin();
    } catch (error) {
      return redirectNoAuth();
    }
  }, [
    login,
    setLoginStatus,
    search,
    redirectNoAuth,
    apiKey,
    checkTokenValidity,
    redirectAuthorize,
    redirectApiKey,
    redirectLogin,
  ]);

  useEffect(() => {
    if (!isLoggingInRef.current) doLogin();
  }, [doLogin, redirectLogin]);

  return (
    <div className='w-full h-screen flex justify-center items-center bg-primary-50'>
      <div className='text-center' style={{ minWidth: '300px' }}>
        <div className='flex justify-center items-center mb-8'>
          <Image src='./images/logo.svg' width='180px' />
        </div>
        <ProgressBar value={loginStatus?.value} showValue={false} style={{ height: '6px' }}></ProgressBar>
        {/* <p className='mt-5 text-primary-800 text-sm'>{loginStatus?.label}</p> */}
      </div>
    </div>
  );
};

export default Login;
