import { useCallback, useEffect } from 'react';

import { Paginator } from 'primereact/paginator';
import { Button } from 'primereact/button';
import { ObjectTypeDropdown, useSelectedObjectType } from '../../../objectTypes';
import RecordsTable from './table/RecordsTable';
import RecordSearchButtonInput from './table/RecordSearchButtonInput';

import { useRecordsList } from '../../hooks';

// See Paginator component for the shape of the page data.
type PageDataType = { first: number; rows: number; page: number; pageCount: number };

export default function RecordList() {
  const [selectedObjectType] = useSelectedObjectType();
  const { setObjectType, records, params, setParams } = useRecordsList({
    objectType: selectedObjectType?.objectTypeId,
  });

  useEffect(() => {
    setObjectType(selectedObjectType?.objectTypeId || null);
  }, [selectedObjectType, setObjectType]);

  // Called when user selects another page.
  const handlePageChange = useCallback(
    (pageData: PageDataType) => {
      if (!params || !selectedObjectType) return;
      setParams({ after: pageData.first, limit: pageData.rows });
    },
    [params, setParams, selectedObjectType],
  );

  return (
    <div className='flex flex-col gap-4 h-full'>
      {/* HEADER */}
      <div className='grid grid-cols-3 py-2 px-1'>
        {/* LEFT */}
        <div className='flex justify-start items-center gap-3 '>
          <ObjectTypeDropdown filter={true} />
          <RecordSearchButtonInput />
        </div>

        {/* CENTER */}
        <div className='flex justify-center items-center'>
          <h4 className='text-2xl font-medium text-slate-600'>{`Select a ${selectedObjectType?.singularLabel || 'Record'}`}</h4>
        </div>

        {/* RIGHT */}
        <div className='flex justify-end items-center gap-3 '>
          <Button
            size='small'
            onClick={() => setParams({})}
            disabled={records.isFetching}
            icon='pi pi-refresh'
            outlined
            severity='secondary'
            loading={records.isFetching}
          />
        </div>
      </div>

      {/* CONTENT */}
      <div className='flex-1 flex flex-col gap-5 border items-center justify-between h-full w-full bg-slate-50 overflow-auto'>
        <RecordsTable />

        <Paginator
          first={Number(params.after) || 0}
          rows={params.limit}
          totalRecords={records?.data?.total || 1}
          onPageChange={(pageData: PageDataType) => handlePageChange(pageData)}
          rowsPerPageOptions={[25, 40, 50]}
        />
      </div>
    </div>
  );
}
