import { useCallback, useEffect } from 'react';
import { Template } from '../../../app/api/templates';
import { useDocumentContext } from './useDocumentContext';
import { useRecords } from '../../records';
import { useTemplates } from '../../templates';

type CreateDocumentParams = {
  templateId: Template['id'];
  objectType: string;
  recordId: string;
};

type CreateDocumentOptions = {
  isLive?: boolean;
};

export function useDocument() {
  const { createDocument: _createDocument, document } = useDocumentContext();
  const { selectedRecord } = useRecords();
  const { selectedTemplate } = useTemplates();

  /**
   * Generate Document callbacks and effects
   */
  const createDocument = useCallback(
    async ({ templateId, objectType, recordId }: CreateDocumentParams, options?: CreateDocumentOptions) => {
      // if (document.data) document.reset();
      const { isLive = true } = options || {};
      return await _createDocument({ templateId, objectType, recordId, isLive }, true);
    },
    [_createDocument],
  );

  useEffect(() => {
    if (!selectedRecord || !selectedTemplate) return;
    createDocument({
      templateId: selectedTemplate.id,
      objectType: selectedRecord?.objectTypeId,
      recordId: selectedRecord.id,
    });
  }, [selectedRecord, createDocument, selectedTemplate]);

  return { createDocument, document };
}
