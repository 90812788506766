/**
 * HeaderWithControls allows for more control over the header of a table column.
 *
 * PrimeReact's data table does have a sort feature, but its state needs to be managed externally.
 * Search functionality is also limited on the data table and I have not currently found a way to only allow
 * searching through the column's filter prop.
 *
 * Future version may contain adaptive options for search, sort, and filter based on the the column's data type.
 *
 * Note: You can display a search field in the header by setting filter to true in the column and setting filter display
 * mode to row in the datatable. However, this will still display the full filter dropdown menu.
 */

// Search functionality is not currently used in the header. See commented out code for implementation.
import { ChangeEvent, forwardRef, Ref, useCallback, useState } from 'react';
import { OverlayPanel } from 'primereact/overlaypanel';
import { InputText } from 'primereact/inputtext';
import { useRecordsList } from '../../../hooks';

interface Props {
  title: string;
  field: string;
  searchable: boolean;
  sortable: boolean;
}
export default function HeaderWithControls({ title, field }: Props) {
  const { params, setParam, setParams } = useRecordsList();

  // Set the sort order based on the current sort state and clear it if the sort key changes

  const handleSortClick = useCallback(() => {
    if (params.sortOrder === null) {
      setParams({ sortBy: field, sortOrder: 'ASCENDING' });
    } else if (params.sortOrder === 'ASCENDING') {
      setParams({ sortBy: field, sortOrder: 'DESCENDING' });
    } else {
      setParam('sortOrder', undefined);
      setParams({ sortOrder: undefined, sortBy: undefined });
    }
  }, [setParams, params, field, setParam]);

  return (
    <>
      <div className='flex w-full items-center justify-start cursor-pointer' onClick={handleSortClick}>
        <h4 className='mr-3'>{title}</h4>
        {/* {sortable && ( */}
        <div className='p-1'>
          {params.sortOrder && (
            <i
              className={`hover:text-slate-600 pi ${' '} ${
                params.sortOrder === 'ASCENDING' ? 'pi-sort-amount-up-alt' : 'pi-sort-amount-down-alt'
              }`}
              style={{ fontSize: '1.2rem' }}
            ></i>
          )}
        </div>
        {/* )} */}
        {/* {searchable && (
          <>
            <div className='p-1' id={searchIconId}>
              <i
                onClick={(e: SyntheticEvent) => searchInputRef?.current?.show(e, document.getElementById(searchIconId))}
                className={`hover:text-slate-600 pi pi-search`}
                style={{ fontSize: '1.2rem' }}
              ></i>
            </div>
            <div id={searchAnchorId} />
            <SearchInput id={searchAnchorId} setSearchValue={setSearchValue} ref={searchInputRef} />
          </>
        )} */}
      </div>
    </>
  );
}

type SearchInputProps = {
  setSearchValue: (value: string) => void;
  id: string;
  ref: Ref<HTMLInputElement>;
};

export const SearchInput = forwardRef<OverlayPanel, SearchInputProps>((props, ref) => {
  const { setSearchValue, id } = props;
  const [localValue, setLocalValue] = useState<string>('');

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setLocalValue(e.target.value);
      if (e.target.value === '') setSearchValue('');
      setTimeout(() => {
        setSearchValue(e.target.value);
      }, 300);
    },
    [setSearchValue],
  );

  const handleBlur = useCallback(() => {
    setLocalValue('');
    setSearchValue('');
  }, [setSearchValue]);

  return (
    <OverlayPanel ref={ref} appendTo={() => document.getElementById(id) || document.body} className='-translate-y-14'>
      <span className='p-input-icon-left'>
        <i className='pi pi-search' />
        <InputText
          type='search'
          autoFocus
          onBlur={handleBlur}
          placeholder='Search'
          onChange={handleChange}
          value={localValue}
          className='p-inputtext-sm w-full max-w-[8rem]'
        />
      </span>
    </OverlayPanel>
  );
});
