import { useContext, useEffect, useMemo } from 'react';
import { useSelectedObjectType } from '../../objectTypes';
import { RecordsContext } from '../contexts/RecordsContext';

export const useRecords = () => {
  const [selectedObjectType] = useSelectedObjectType();

  const { fetchRecords, records, selectedRecord, setSelectedRecord } = useContext(RecordsContext);

  const objectTypeHasChanged = useMemo(() => {
    const { objectType } = records?.originalArgs || {};
    const { objectTypeId, objectTypeName } = selectedObjectType || {};
    return objectTypeName !== objectType && objectTypeId !== objectType;
  }, [records?.originalArgs, selectedObjectType]);

  useEffect(() => {
    if (objectTypeHasChanged && selectedObjectType) {
      setSelectedRecord(null);
      fetchRecords({ objectType: selectedObjectType?.objectTypeId, queryParams: {} });
    }
  }, [selectedObjectType, objectTypeHasChanged, fetchRecords, setSelectedRecord]);

  return { records, fetchRecords, selectedRecord, setSelectedRecord };
};

export default useRecords;
