import { Dialog } from 'primereact/dialog';
import RecordList from './RecordList';
import useRecordsUI from '../../hooks/useRecordsUI';
import { RecordsListProvider } from '../../contexts/RecordsListContext';

export default function RecordListModal() {
  const { closeModal, recordModalVisible } = useRecordsUI();
  return (
    <Dialog
      onHide={closeModal}
      headerStyle={{ paddingBlock: 7 }}
      modal
      style={{ width: '80%', height: '90%' }}
      visible={recordModalVisible}
      draggable={false}
    >
      <RecordsListProvider>
        <RecordList />
      </RecordsListProvider>
    </Dialog>
  );
}
