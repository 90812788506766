import { createContext, SetStateAction,  useState } from 'react';
import { useLazyGetRecordsQuery } from '../../../app/api/records';
import type { Dispatch } from 'react';
import { ListQueryParams } from '../../../app/api/types';

export interface RecordsListContextType {
  fetchRecords: ReturnType<typeof useLazyGetRecordsQuery>[0];
  records: ReturnType<typeof useLazyGetRecordsQuery>[1];
  params: ListQueryParams;
  setParams: Dispatch<SetStateAction<ListQueryParams>>;
  objectType: string | null;
  setObjectType: Dispatch<SetStateAction<string | null>>;
}

export const initialState = {
  fetchRecords: (async () => ({ data: undefined }) )as unknown as ReturnType<typeof useLazyGetRecordsQuery>[0],
  records: { data: undefined, error: undefined, isLoading: false, isFetching: false, isSuccess: false },
  params: { limit: 25, after: 0, sortBy: undefined, sortOrder: undefined },
  setParam: () => {},
  setParams: () => {},
  resetParams: () => {},
  objectType: null,
  setObjectType: () => {},
};

export const RecordsListContext = createContext<RecordsListContextType>(initialState);

export function RecordsListProvider({ children }: { children: React.ReactNode }) {
  const [fetchRecords, records] = useLazyGetRecordsQuery();
  const [objectType, setObjectType] = useState<string | null>(null);
  const [params, setParams] = useState<ListQueryParams>(initialState.params);

  return (
    <RecordsListContext.Provider value={{ fetchRecords, records, params, setParams, objectType, setObjectType }}>
      {children}
    </RecordsListContext.Provider>
  );
}

export default RecordsListProvider;
