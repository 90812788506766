import { useCallback, useContext, useEffect } from 'react';
import { RecordsListContext, initialState } from '../contexts/RecordsListContext';
import { ListQueryParams } from '../../../app/api/types';
import _ from 'lodash';

type ParamSetter = <T extends ListQueryParams, K extends keyof T>(key: K, value: T[K]) => void;
type ListRecordsReturnType = void;
type ListRecordsType = (args?: { objectType?: string; params?: ListQueryParams }) => Promise<ListRecordsReturnType>;

export const useRecordsList = (initialConfig?: { objectType?: string }) => {
  const {
    fetchRecords,
    records,
    params,
    objectType,
    setObjectType,
    setParams: _setParams,
  } = useContext(RecordsListContext);

  const listRecords = useCallback<ListRecordsType>(
    async (args) => {
      if (!objectType || records.isFetching) return;
      fetchRecords({ objectType, queryParams: params });
    },
    [objectType, params, fetchRecords, records.isFetching],
  );

  if (initialConfig?.objectType && initialConfig.objectType !== objectType) {
    setObjectType(initialConfig.objectType);
  }

  const setParam = useCallback<ParamSetter>(
    (paramName, paramValue) => _setParams((prev) => ({ ...prev, [paramName]: paramValue })),
    [_setParams],
  );

  const setParams = useCallback<(params: ListQueryParams) => void>(
    (params) => _setParams((prev) => ({ ...prev, ...params })),
    [_setParams],
  );

  const resetParams = useCallback(() => _setParams(initialState.params), [_setParams]);

  useEffect(() => {
    const prevArgs = records?.originalArgs || {};
    const objectTypeHasChanged = objectType !== prevArgs.objectType;
    const paramsHaveChanged = !_.isEqual(params, prevArgs.queryParams);
    if (objectType && !records.isFetching && (paramsHaveChanged || objectTypeHasChanged)) {
      listRecords({ params, objectType });
    }
  }, [params, objectType, listRecords, records]);

  return { listRecords, records, params, setParams, setParam, setObjectType, resetParams };
};

export default useRecordsList;
